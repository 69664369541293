import { useEffect, useState } from 'react';
import { ButtonBase, Checkbox, Grid, Slide, useMediaQuery, useTheme  } from '@material-ui/core';
import Select from 'react-select';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useAlert } from 'react-alert';
import { makeStyles } from '@material-ui/core';
import { WarningIcon } from 'components/Icons';
import { defaultAlertError, questionPassReasons, questionPassOptions } from 'utils/common/constants';
import { questionPass } from 'store/creator';
import { updateMessenger } from 'utils/intercomConfig';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { queryCreatorTypes } from 'store/creator';
import { queryProfileTypes  } from 'store/auth/profile';
import { updateCreatorStatus } from 'store/creator';

const useStyles = makeStyles((theme) => ({
  slideWrapper: {
    position: 'fixed',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
    zIndex: 2000,
    bottom: 0,
  },
  slideContainer: {
    width: 650,
    backgroundColor: theme.common.black,
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    padding: '20px 40px',
    marginLeft: 176, // Nav offset
    [theme.breakpoints.only('xs')]: {
      width: '100%',
      padding: 20,
      marginLeft: 0, // No offset on mobile
    },
  },
  textContainer: {
    [theme.breakpoints.only('xs')]: {
      padding: 0,
    },
  },
  heading: {
    textAlign: 'center',
    color: theme.common.white,
    fontFamily: 'Figtree-Bold',
    fontSize: 20,
    paddingBottom: 2,
  },
  message: {
    lineHeight: 1.2,
    whiteSpace: 'pre-wrap',
    textAlign: 'center',
    color: theme.common.white,
    fontSize: 14,
    padding: '0 10px',
  },
  btnContainer: {
    marginTop: 20,
  },
  cancelButton: {
    height: 44,
    padding: 0,
    borderRadius: 5,
    border: '1px solid white',
    fontFamily: 'Figtree-Bold',
    fontSize: 16,
    lineHeight: '19.2px',
    color: 'white',
    '&:hover': {
      color: 'white !important',
    },
    width: '100%',
  },
  passButton: {
    height: 44,
    padding: 0,
    borderRadius: 5,
    backgroundColor: '#495EFF',
    fontFamily: 'Figtree-Bold',
    fontSize: 16,
    lineHeight: '19.2px',
    color: 'white',
    '&.disabled': {
      backgroundColor: theme.grey[500],
      cursor: 'not-allowed',
    },
    width: '100%',
  }
}));

const AwaySlide = (props) => {
  const { 
    open,
    onClose,
    onOptionSelected,
    customerName,
    isCreatorAway,
    isCreatorPpa
  } = props;

  const { breakpoints } = useTheme();
  const classes = useStyles();
  const mobileView = useMediaQuery(breakpoints.down('xs'));

  const alert = useAlert();
  const queryClient = useQueryClient();

  let headingText = 'You’re in away mode.';
  let messageText = 'You can still answer questions while Away.';
  let cancelButtonText = 'Stay "Away" and Answer';
  let confirmButtonText = 'Go "Active" and Answer';

  if (isCreatorPpa) {
    cancelButtonText = 'Answer without Payment';

    if (isCreatorAway) {
      messageText = 'You can still answer questions while Away. When you switch to Active, we request payment details for questions you recieved while in Away mode.';
      confirmButtonText = 'Go "Active" and Request Payment';
    } else {
      messageText = `We have requested payment details from ${customerName}.\nYou can answer this question anytime without payment.`;
      confirmButtonText = 'Await Payment';
    }
  }

  const { mutate: onUpdateCreatorStatus } = useMutation({
    mutationFn: updateCreatorStatus,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [queryProfileTypes.profile] });
      queryClient.invalidateQueries({ queryKey: [queryCreatorTypes.inbox] });
      if (isCreatorPpa) {
        onClose();
      } else {
        onOptionSelected();
      }
    },
    onError: (err) => {
      alert.error(null, {...defaultAlertError, offsetError: true });
      onClose();
    },
  });

  return (
    <Slide direction="up" in={open} mountOnEnter unmountOnExit>
      <div className={classes.slideWrapper}>
        <div className={`${classes.slideContainer} offset`}>
          <Grid
            container
            direction='column'
            alignItems='center'
            justifyContent='center'
            className={classes.contentContainer}
          >
            <Grid item>
                <WarningIcon />
            </Grid>
            <Grid item className={classes.textContainer}>
              <div className={classes.heading}>{headingText}</div>
              <div className={classes.message}>{messageText}</div>
            </Grid>
          </Grid>
          <Grid 
            container 
            item 
            direction={mobileView ? 'column' : 'row'} 
            justifyContent='center'
            className={classes.btnContainer}
            spacing={2}
          >
            <Grid item style={{ flex: 1 }}>
              <ButtonBase 
                className={classes.cancelButton}
                onClick={onOptionSelected}
              >
                {cancelButtonText}
              </ButtonBase>
            </Grid>
            <Grid item style={{ flex: 1}}>
              <ButtonBase 
                className={classes.passButton}
                onClick={() => {
                  if (isCreatorPpa && !isCreatorAway) {
                    onClose();
                  } else {
                    onUpdateCreatorStatus({ is_active: true })
                  }
                }}
              >
                {confirmButtonText}
              </ButtonBase>
            </Grid>
          </Grid>
        </div>
      </div>
    </Slide>
  )
};

export default AwaySlide;